<template>
  <div class="web-container">
    <div class="page-box" v-if="!webglShow">
      <!--页面顶部-->
      <div class="header-container ">
        <div class="page-header">
          <div class="flex flex-between">
            <div class="web-title flex flex-start">
              <div class="name">教育部全国高校思政课虚拟仿真体验教学中心</div>
              <img src="http://resouce.cdzyhd.com/79d7bbdb-aa06-41df-940a-649f26a93663.png" alt="" class="logo">
              <img src="http://resouce.cdzyhd.com/3ea93d70-e7c6-4b51-8d2a-6ca04d40403e.png" alt="" class="logo1">
            </div>
            <div class="nav-box flex flex-start">
              <a href="javascript:void(0)" @click="selectNav('1')">中心介绍</a>
              <a href="javascript:void(0)" @click="selectNav('3')">项目体验</a>
              <a href="javascript:void(0)" @click="selectNav('4')">友情链接</a>
            </div>
          </div>
        </div>
      </div>
      <!--轮播图-->
      <div class="focus-container ">
        <el-carousel :interval="6000" height="520px">
          <el-carousel-item v-for="item in webConfig.focusList" :key="item" style="text-align: center">
            <el-image
                style=""
                :src="item"
                fit="fill"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!--中心介绍-->
      <a href="#" name="center"></a>
      <div class="li-container content-container">
        <el-divider content-position="center" class="content-title">全国高校思政课虚拟仿真体验教学中心介绍</el-divider>
        <div class="content-html html-view">
          <p>
            2019年3月18日，习近平总书记在学校思想政治理论课教师座谈会上强调“推动思想政治理论课改革创新，要不断增强思政课的思想性、理论性和亲和力、针对性”。2019年，中共中央办公厅、国务院办公厅印发的《关于深化新时代学校思想政治理论课改革创新的若干意见》指出，“大力推进思政课教学方法改革，提升思政课教师信息化能力素养，推动人工智能等现代信息技术在思政课教学中应用，建设一批国家级虚拟仿真思政课体验教学中心”。</p>
          <p>
            2018年以来，为推动思政课改革创新，西南财经大学聚焦现代信息技术与思政课的有机融合，成立了思政课虚拟仿真体验教学中心，同时，与成都智云鸿道信息技术有限公司联合成立了西南财经大学思政虚拟仿真联合实验室。双方资源整合、优势互补，校企联合研发有“红军长征在四川”系列、“两弹一星”精神和“汶川地震之生死营救”等9项虚拟仿真体验教学项目，有力推进了思政课虚拟仿真体验教学资源建设，让思政课真正“活”起来！</p>
          <p>
            经过几年的改革探索，思政课虚拟仿真教学建设取得显著成果。2021年获批教育部全国高校思政课虚拟仿真体验教学中心、四川省思想政治理论课虚拟仿真实验教学中心；获批立项2021年教育部思政专项重大项目——“现代信息技术促进思政课教学质量提升研究”；
            荣获2023年国家级教学成果奖二等奖1项、2021年四川省高等教育教学成果奖一等奖1项；先后入选四川省高校思想政治理论课名师工作室2个；课程资源入选“国家虚拟仿真实验教学课程共享平台”“国家智慧教育公共服务平台”；校、院入选四川省“三全育人”改革试点单位和学院；培育形成了一支能够熟练应用虚拟仿真技术开展教学的思政课教师队伍。</p>
          <p>中心成果广泛应用于全国大中小学思政课教学，受益师生超过400万人次。成果不仅服务于“成渝地区双城经济圈”教育文化建设，在西南政法大学、北京青年政治学院等百余所高校得到推广运用。通过开展“重走长征路
            红课送老区”活动，四川凉山州宁南中学、重庆酉阳车田乡小学等全国数十所中小学广泛受益。</p>
          <p>
            中心成果应用于“不忘初心、牢记使命”主题教育、“四史”学习教育和庆祝建党100周年等活动。受邀参加中国文物交流中心、国家大剧院、中国人民革命军事博物馆联合主办的“不忘来时路永远跟党走”主题展，中国文物交流中心、中国人民革命军事博物馆等单位主办的《红色印记——革命文物背后的故事》主题展，第二届、第三届“丝路国际合作高峰论坛”，以及教育部“网上重走长征路暨四史教育活动”启动仪式。</p>
          <p>
            2019年以来，教育部、四川省委、省教育厅领导先后来校视察和指导，对本成果改革取得的进展和实际效果给予充分肯定和鼓励。全国多位思政专家给予本成果高度评价。中央电视台、新华社、教育部官网等主流媒体多次报道本项改革成果。2021年，新华社宣传报道的“西南财经大学创新党史学习教育
            推行‘长征系列’虚拟仿真实验教学”，浏览量超过200万；2022年，项目亮相CCTV-7国防军事频道《军迷行天下》栏目《为了让红色文物“活起来”》，激发了青少年对革命文物的极大兴趣；2023年，项目亮相中央广播电视总台制作的大型电视专题片《长征之歌》，后在央视多套节目播出，该纪录片全网总点击量达13.4亿；2023年12月，《中国教育报》以《让思政课插上“数字的翅膀”》为题进行专版报道。</p>
          <p>
            面向未来，中心将进一步围绕立德树人根本任务，聚焦思政课虚拟仿真体验教学资源建设及推广应用，持续推进现代信息技术与思政课的深度融合，为切实推动思政课教学提质增效贡献西南财经大学力量。</p>
        </div>
      </div>
      <!--项目介绍-->
      <a href="#" name="experiment"></a>
      <div class="content-container experiment-container">
        <el-divider content-position="center" class="content-title">项目体验</el-divider>
        <div class="experiment-list">
          <div class="sub-list flex flex-start flex-wrap">
            <div class="sub-li" v-for="(experimentItem,experimentIndex) in experiments.list">
              <img :src="experimentItem.product_iconLeft" alt="" class="left-icon"
                   v-if="experimentItem.product_iconLeft">
              <div class="bg">
                <img :src="experimentItem.product_bg+'?imageView2/1/w/290/h/183/q/75'">
              </div>
              <div class="info">
                <img class="avatar" :src="experimentItem.product_icon" alt="">
                <div class="flex flex-dr flex-center">
                  <div class="title">{{ experimentItem.name }}</div>
                  <div class="des-main word-hr ellipsis">{{ experimentItem.subName }}</div>
                  <!--最多3行 每行18字 第三行超出省略号显示-->
                  <div class="des word-hr ellipsis" v-html="experimentItem.description"></div>
                  <div class="buttons">
                    <a class="button"
                       @click="ExperimentMethods().clickViewExperiment(experimentItem)">查看详情</a>
                  </div>
                </div>
              </div>
              <div class="use-buttons">
                <el-button type="success" @click="ExperimentMethods().clickUseExperiment(experimentItem)">
                  {{ experimentItem.name !== '汶川大地震' ? '在线体验' : '下载体验' }}
                </el-button>
              </div>
            </div>
            <div class="sub-li more-li">
              <div class="bg">
                <img src="http://resouce.cdzyhd.com/08aba1b4-255f-4540-8089-f947243218d5.png">
              </div>
              <div class="info">

              </div>
            </div>
          </div>
        </div>
      </div>
      <!--产品介绍弹窗-->
      <div v-if="experimentInfo.dialog" class="my-dialog-container flex flex-dr flex-center experiment-dialog">
        <div class="mask" @click="ExperimentMethods().closeExperimentDialog()"></div>
        <div class="dialog-box">
          <div class="bg"></div>
          <img src="@/assets/icon/dialog_close.png" alt="关闭" class="close-icon"
               @click="ExperimentMethods().closeExperimentDialog()">
          <div class="info-box">
            <div class="header-box flex flex-start">
              <img :src="experimentInfo.experiment.product_icon" class="avatar" alt="">
              <div class="name-box">
                <div class="name">{{ experimentInfo.experiment.name }}</div>
                <div class="series">{{ experimentInfo.experiment.seriesName }}</div>
              </div>
              <div>

              </div>
            </div>
            <div class="description-box">
              <div class="title">实验简介：</div>
              <div class="content">
                <p v-for="p in experimentInfo.experiment.experimentDescription.split('\n')">{{ p }}</p>
              </div>
            </div>
            <div class="view-box flex flex-between">
              <div class="video-box" v-if="experimentInfo.experiment.product_video">
                <video id="v1" loop="" preload="" muted="" x-webkit-airplay="true" airplay="allow"
                       webkit-playsinline="true" playsinline="true" :src="experimentInfo.experiment.product_video"
                       class="video1 video"
                       draggable="true"></video>
                <img src="@/assets/product/dialog/play.png" alt="" class="play-btn"
                     @click="e=>ExperimentMethods().playVideo(e)">
              </div>
              <el-image v-if="!experimentInfo.experiment.product_video" class="video-box-no" fit="cover"
                        :src="experimentInfo.experiment.product_info_img"
                        :preview-src-list="experimentDialogPicList"></el-image>
              <div class="img-box flex flex-around flex-wrap">
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_1"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_2"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_3"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
                <el-image
                    class="img"
                    fit="cover"
                    :src="experimentInfo.experiment.product_info_img_4"
                    :preview-src-list="experimentDialogPicList">
                </el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--团队介绍-->
      <a href="#" name="member"></a>
      <!--友情链接-->
      <a href="#" name="link"></a>
      <div class="link-container content-container">
        <el-divider content-position="center" class="content-title">友情链接</el-divider>
        <div class="list flex flex-around flex-wrap">
          <div v-for="item in linkConfig.list" v-if="item.img" @click="clickLink(item)">
            <el-image :src="item.img" alt="" class="li" fit="contain"></el-image>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <!--底部-->
      <div class="page-footer">
        <div class="info-container content-container">
          <div class="infos">
            <img :src="webConfig.logoUrl" alt="" class="logo">
            <p>{{ webConfig.webName }}</p>
            <p>主办：{{ webConfig.colleageName }}</p>
            <span style="cursor: pointer" @click="window.open('https://beian.miit.gov.cn/')">
            &nbsp;&nbsp;&nbsp;&nbsp;{{
                webConfig.beiAnText
              }}</span>
          </div>
        </div>
      </div>

      <template>
        <el-backtop :bottom="50"></el-backtop>
      </template>

      <!--弹窗-输入账号密码-->
      <el-dialog
          :close-on-click-modal="false"
          title="开始体验"
          :visible.sync="useShow"
          width="500px"
          center
          v-el-drag-dialog>
        <div class="dialog-container" v-if="experiment.name!=='汶川大地震'">
          <div class="text-box">
            <div class="title" style="text-align:center;">实验提示</div>
            <div class="des" style="text-align: left;">
              <div style="color:red;">请用【电脑】的【火狐/谷歌浏览器】进行体验</div>
              <div style="margin-top:5px;margin-bottom:5px;">
                <a href="https://www.firefox.com.cn/" target="_blank"
                   style=" font-size: 15px;color:#0033cc;text-decoration: none;;margin-right: 10px;">点击下载火狐浏览器</a>
                <a href="https://www.google.cn/chrome/" target="_blank"
                   style=" font-size: 15px;color:#0033cc;text-decoration: none;">点击下载谷歌浏览器</a>
              </div>
              <div style="margin-top: 10px" class="li">
                <div style="font-weight: bold;margin-bottom:3px;">电脑配置要求：</div>
                <div>显卡（独立显卡）：NVIDIA GTX 750Ti 或更高性能独立显卡</div>
                <div>处理器：Intel 8 代 I3 处理器或更高性能处理器</div>
                <div>内存：8GB或更高</div>
                <div>系统：Windows或Mac</div>
              </div>
            </div>
          </div>
          <el-form style="width: 300px;margin:0 auto;">
            <el-form-item label="账号：" style="margin-top: -15px">
              <el-input type="text" v-model="use.username"></el-input>
            </el-form-item>
            <el-form-item label="密码：" style="margin-top: -15px">
              <el-input type="text" v-model="use.password"></el-input>
            </el-form-item>
          </el-form>
          <div class="flex flex-center">
            <el-button type="primary" @click="ExperimentMethods().clickStartExperimentBtn()">开始体验</el-button>
          </div>
        </div>
        <div class="dialog-container" v-if="experiment.name==='汶川大地震'">
          <div class="text-box">
            <div class="title" style="text-align:center;">实验提示</div>
            <div class="des" style="text-align: left;">
              <div style="margin-top: 10px" class="li">
                <div style="font-weight: bold;margin-bottom:3px;">电脑配置要求：</div>
                <div>显卡（独立显卡）：NVIDIA GTX 750Ti 或更高性能独立显卡</div>
                <div>处理器：Intel 8 代 I3 处理器或更高性能处理器</div>
                <div>内存：8GB或更高</div>
                <div>系统：Windows或Mac</div>
              </div>
              <div style="margin-top: 10px" class="li">
                <div style="font-weight: bold;margin-bottom:3px;">使用说明：</div>
                <div>1、软件资源包是压缩包，请先使用winrar等解压软件进行解压缩，全部解压后进入解压后的文件夹，双击软件名称.exe打开软件。进入软件后，输入上方的账号信息正式开始。选择题等操作题，错误3次后会出现问号提示按钮，可以点击查看参考答案。</div>
                <div>2、最好使用带有独立显卡的台式电脑运行本教学软件，以获得更好的体验效果，集成显卡电脑部分交互场景可能会卡顿。</div>
                <div>3、支持windows10和windows11系统。</div>
                <div>4、登录时需要连接网络。</div>
                <div>5、请确保显示器分辨率是1920X1080，或其它16:9的显示比例，否则软件可能显示不全。</div>
                <div style="color:red;">登录时学校名称请填写为：西南财经大学</div>
              </div>
              <div style="color:red;margin-top: 20px;text-align: center">汶川大地震-百度网盘下载链接</div>
<!--              <div style="color:red;margin-top: 20px;text-align: center">http://resouce.cdzyhd.com/tmp/汶川大地震-240914.rar</div>-->
              <div style="color:red;margin-top: 20px;text-align: center">https://pan.baidu.com/s/1exX3MzG4Wd_LWWeiK9pyGA?pwd=1234</div>
            </div>
          </div>
        </div>
      </el-dialog>
    </div>

    <!--实验webgl弹窗-->
    <div style="width: 100%;position: relative" class="webgl-box" v-if="webglShow">
      <iframe id="webgl" v-if="webglShow" :src="webUrl"></iframe>
      <el-button class="fullBtn" type="danger" @click="ExperimentMethods().enterFullScreen()">全屏体验</el-button>
    </div>
  </div>
</template>

<script>
import elDragDialog from "@/directive/el-drag-dialog";
import {API_URL_ERP, H5_URL, URL_ERP} from "@/model/ConfigModel";
import {isMobile} from "@/utils/common";
import {OfficialWebExperimentModel} from "@/model/erp/OfficialWebExperimentModel";
import {msg_err} from "@/utils/ele_component";

export default {
  name: "web_xncjdx",
  directives: {
    elDragDialog
  },
  data() {
    return {
      window: window,
      navIndex: "",
      webConfig: {
        "logoUrl": "",
        "schoolName": "西南财经大学",
        "systemName": "思政课虚拟仿真体验教学中心",
        "colleageName": "西南财经大学马克思主义学院",
        "focusList": [
          "http://resouce.cdzyhd.com/4da1e424-b8bf-4da3-99e0-29d3e22a9744.png",
          "http://resouce.cdzyhd.com/a64c34de-c0d4-41a5-8a12-3ef39c662eb8.png",
          "http://resouce.cdzyhd.com/3c4f86b4-ee48-48a5-bbe2-0945fd4104d9.png",
          "http://resouce.cdzyhd.com/419c4e33-a189-420b-be9b-31e7c10bf329.jpg",
          "http://resouce.cdzyhd.com/1922d40e-9d1f-4176-84cd-74918a080b9b.jpg",

        ],
        "webName": "教育部全国高校思政课虚拟仿真体验教学中心（西南财经大学）",
        beiAnText: "蜀ICP备19034784号-1"
      },
      introduceConfig: {
        "tabs": [{
          "name": "中心简介",
          "showNav": true,
          "text": "",
          "id": "1661264029372"
        }, {
          "name": "教学团队",
          "showNav": true,
          "text": "",
          "id": "1661264039431"
        }], "name": "中心介绍"
      },
      experiments: {
        list: []
      },
      experimentInfo: {
        dialog: false,
        experiment: {},
        list: []
      },
      experiment: {},
      memberActive: "1",
      memberList: [
        {
          list: []
        },
        {
          list: []
        },
      ],
      member: {
        dialog: false,
        info: {}
      },
      linkConfig: {
        list: [
          {
            "id": 1661270247796,
            "img": "http://resouce.cdzyhd.com/79f8e262-4a95-4411-8d6e-02e999e843d2.jpg",
            "name": "马克思主义学院",
            "url": "https://mks.swufe.edu.cn/"
          }
        ]
      },
      useShow: false,
      use: {
        username: "",
        password: "",
      },
      webglShow: false,
      webUrl: ""
    }
  },
  created() {
    if (isMobile()) {
      window.location.href = H5_URL + this.$route.path
    }
  },
  mounted() {
    document.title = this.webConfig.webName
    // 判断是不是在移动端
    this.getExperimentConfig()
  },
  methods: {
    // 获取产品配置
    async getExperimentConfig() {
      // 要获取的实验列表，和自定义属性
      let experimentList = [
        {id: "1618400683877", name: "四渡赤水"},
        {id: "1618400078717", name: "彝海结盟"},
        {id: "1617350115033", name: "飞夺泸定桥"},
        {id: "1618399838791", name: "爬雪山过草地"},
        {id: "1644387517358", name: "两弹一星"},
        {id: "1666665227109", name: "汶川大地震"},
        {id: "1706757608126", name: "习近平总书记的人民情怀——“悬崖村”脱贫之路"},
        {id: "1706757339307", name: "井冈星火"},
        {id: "1706757514906", name: "建党伟业"},
      ]
      let list = []
      for (let i = 0; i < experimentList.length; i++) {
        let id = experimentList[i]["id"]
        let experiment = await OfficialWebExperimentModel.getOneExperimentConfig(id)
        let experimentLast = Object.assign({}, experiment, experimentList[i])
        // 合并自定义项
        list.push(experimentLast)
        this.$set(this.experiments, "list", list)
      }
    },
    // 点击开始实验按钮
    clickUseLi(index) {
      // 四渡赤水
      if (index === 1) {
        window.open("http://resouce.cdzyhd.com/experiment/webgl/sdcs/xicai/2022051901/index.html")
      }
      // 飞夺泸定桥
      if (index === 2) {
        window.open("http://resouce.cdzyhd.com/experiment/webgl/sdcs/fdldq/2022051901/index.html")
      }
    },
    // 点击首页导航
    selectNav(v) {
      switch (v) {
        case "1":
          window.location.href = "#center"
          break;
        case "2":
          window.location.href = "#use"
          break;
        case "3":
          window.location.href = "#experiment"
          break;
        case "4":
          window.location.href = "#link"
          break;
        case "5":
          window.location.href = "/login?schoolId=59977128740917248"
          break;
      }
    },
    // 点击团队成员
    clickMember(item, index) {
      this.$set(this.member, "info", item)
      this.member.dialog = true
    },
    // 点击友情链接
    clickLink(item) {
      window.open(item.url)
    },
    // 实验项目方法集
    ExperimentMethods() {
      let $this = this;
      return {
        clickUseExperiment(experiment) {
          $this.useShow = true
          $this.experiment = experiment
        },
        clickStartExperimentBtn() {
          // 账号判断
          $.ajax({
            url: API_URL_ERP + "/v1/activity/experimentCode/useCode",
            data: JSON.stringify({
              code: $this.use.username,
            }),
            contentType: "application/json",
            type: "post",
            success: function (result) {
              if (result.code === "000000") {
                if ($this.use.password !== "123456") {
                  msg_err("账号或密码错误！")
                  return
                }
                switch ($this.experiment.name) {
                  case "四渡赤水":
                    $this.webUrl="https://vlab.swufe.edu.cn/vlab_files/threeGen/0005/2024/030/0883/4osat/index.html?token=1"
                    break;
                  case "彝海结盟":
                    $this.webUrl = "https://vlab.swufe.edu.cn/vlab_files/threeGen/0005/2024/092/0720/14ekk/index.html?token=1"
                    break;
                  case "飞夺泸定桥":
                    $this.webUrl="https://vlab.swufe.edu.cn/vlab_files/threeGen/0005/2024/030/0877/4n5me/index.html?token=1"
                    break;
                  case "爬雪山过草地":
                    $this.webUrl="https://vlab.swufe.edu.cn/vlab_files/threeGen/0005/2024/030/0893/4tqbb/index.html?token=1"
                    break;
                  case "两弹一星":
                    $this.webUrl="https://vlab.swufe.edu.cn/vlab_files/threeGen/open/2024/029/1395/480vn/webgl/index.html?token=1"
                    break;
                  case "汶川大地震":
                    break;
                  case "习近平总书记的人民情怀——“悬崖村”脱贫之路":
                    $this.webUrl="https://vlab.swufe.edu.cn/vlab_files/threeGen/open/2024/030/1077/74h0m/webgl/index.html?token=1"
                    break;
                  case "井冈星火":
                    $this.webUrl="https://vlab.swufe.edu.cn/vlab_files/threeGen/0005/2024/102/0838/1dqgar/index.html?token=1"
                    break;
                  case "建党伟业":
                    $this.webUrl="https://vlab.swufe.edu.cn/vlab_files/threeGen/0005/2024/102/0881/1dvbk1/index.html?token=1"
                    break;
                }
                $this.webglShow = true
              } else {
                msg_err("账号或密码错误！")
              }
            }
          });
        },
        // webgl iframe进入全屏
        enterFullScreen(){
          let iframe = document.getElementById("webgl")
          if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
          } else if (iframe.webkitRequestFullscreen) { /* Safari */
            iframe.webkitRequestFullscreen();
          } else if (iframe.msRequestFullscreen) { /* IE11 */
            iframe.msRequestFullscreen();
          }
        },
        clickViewExperiment(experiment) {

          $this.experimentInfo.dialog = true
          document.body.style.overflow = 'hidden'
          $this.experimentDialogEnterBtn = false
          $this.$set($this.experimentInfo, "experiment", experiment);
          $this.experimentDialogPicList = [
            experiment.product_info_img_1,
            experiment.product_info_img_2,
            experiment.product_info_img_3,
            experiment.product_info_img_4,
          ]
          if (!experiment.product_video && experiment.product_info_img) {
            $this.experimentDialogPicList.push(experiment.product_info_img)
          }
          setTimeout(() => {
            $(".experiment-dialog .bg").css("background-image", `url(${experiment.product_info_bg})`)
          }, 100)
        },
        // 关闭实验弹窗
        closeExperimentDialog() {
          $this.experimentInfo.dialog = false
          document.body.style.overflow = ''
        },
        // 播放视频
        playVideo(e) {
          // this.videoPlay = true
          // document.querySelector("#v1").play()
          $this.experimentInfo.experimentVideoDialog = true
        }
      }
    }
  }
}
</script>
<style scoped lang="less">
@import '../../style/app.less';

#webgl {
  display: inline-block;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
}

.web-container {
  //background-color: #f2f2f2;
}

.content-container {
  &:hover {
    .el-divider__text {
      color: #4093f9;
      font-size: 24px;
    }

  }
}

.content-title {
  margin-top: 40px;

  .el-divider__text {
    font-size: 22px;
    color: #777;
    font-weight: 450;
    text-align: center;
    margin-bottom: 20px;
  }

}

.header-container {
  width: 100%;
  background-color: #215287;
}

.page-header {
  width: 1280px;
  margin: 0 auto;
  padding: 5px 0px;
  background-color: #215287;
  z-index: 1999;
  color: #fff;

  .web-title {
    position: relative;

    .name {
      position: absolute;
      color: #fff;
      left: 90px;
      top: 62px;
      font-size: 10px;
    }

    .logo {
      width: 370px;
      height: 92px;
      cursor: pointer;
      font-weight: normal;
    }

    .logo1 {
      width: 335px;
      height: 92px;
      cursor: pointer;
      margin-left: -25px;
    }


  }

  .nav-box {
    a {
      color: #fff;
      margin-right: 30px;
      text-decoration: none;
      font-size: 15px;
      display: inline-block;
      text-align: center;
      width: 110px;

      &:hover {
        font-size: 17px;
      }
    }
  }
}

.focus-container {

}

.user-container {
  .use-box {
    margin-top: 35px;

    .li {
      position: relative;

      img.bg {
        width: 600px;
        object-fit: contain;
      }

      img.button {
        width: 164px;
        height: 44px;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 80%;
        z-index: 2;
        cursor: pointer;
      }

      img.button:hover {
        content: url("http://resouce.cdzyhd.com/dac55cec-3d17-44e4-9e29-b2dcfcbbee44.png");
      }
    }
  }
}

.li-container {
  margin-bottom: 50px;

  .content-html {
    margin-top: 45px;

    text-indent: 2em;
    font-size: 15px;
    color: #666;
    line-height: 25px;
    letter-spacing: 1px;
  }
}

.use-buttons {
  text-align: center;
  margin-top: 20px;

  .button-use {
    background-color: #fe1d16;
    color: #fff !important;
    padding: 5px 20px;
    margin-right: 10px;
    font-size: 16px;
    border-radius: 20px;
    text-decoration: none;
  }
}

.experiment-list {
  margin-top: 55px;


  .sub-list {
    .sub-li {
      margin-bottom: 20px;
      margin-right: 40px;
      position: relative;

      &:nth-child(4n+4) {
        margin-right: 0px;
      }

      &:hover {
        .bg {
          img {
            transition: all 0.5s linear;
            transform: scale(1.1);
          }
        }

        .info {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

          .avatar {
            margin-top: -30px;
            opacity: 0;
          }


          .des-main {
            opacity: 0;
          }

          .title {
            margin-top: -30px;
            margin-bottom: 0px;

          }

          .des {
            display: -webkit-box;
          }

          .buttons {

            display: block;
          }
        }

      }


      img.left-icon {
        position: absolute;
        width: 60px;
        height: 60px;
        top: -10px;
        left: -10px;
        z-index: 10;
      }

      .bg {
        z-index: 1;
        width: 290px;
        height: 183px;
        overflow: hidden;
        position: relative;

        img {
          width: 290px;
          height: 183px;
          display: block;
          transition: all 0.5s linear;
          transform: scale(1);

        }
      }

      .info {
        position: relative; // 解决margin-top负数 和同级div的z-index问题
        padding: 25px;
        height: 180px;
        z-index: 2;
        width: 270px;
        margin: 0 auto;
        margin-top: -60px;
        text-align: center;
        background: #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-bottom: 3px solid transparent;
        transition: all 0.2s linear;
        overflow: hidden;

        .avatar {
          display: inline-block;
          width: 60px;
          height: 60px;
          position: relative;
          margin-top: 0px;
          transition: all 0.2s linear;
          border-radius: 4px;
          box-shadow: 2px 2px 5px 1px #dedede;
        }

        .title {
          position: relative;
          font-size: 16px;
          font-weight: bold;
          margin-top: 18px;
          margin-bottom: 10px;
          transition: all 0.2s linear;
        }

        .des-main {
          transition: all 0.2s linear;
          color: #666;
          font-size: 14px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }

        .des {
          font-weight: 300;
          font-size: 14px;
          display: none;
          color: #666;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        .buttons {
          display: none;
          text-align: center;
          margin-top: 12px;
        }

        .button {
          width: 100px;
          line-height: 28px;
          border: 1px solid #d8d8d8;
          border-radius: 15px;
          text-align: center;
          font-size: 14px;
          color: #666666;
          position: relative;
          cursor: pointer;
          transition: all 0.2s linear;
          text-decoration: none;
          padding: 5px 20px;

          &:hover {
          }
        }

      }
    }

    .more-li {
      .info {
        background-image: url("http://resouce.cdzyhd.com/c8db5de2-7b9d-46f2-a517-35af74946245.png");
      }
    }
  }
}

.experiment-dialog {
  border-radius: 4px;
  z-index: 2000; // 解决被遮挡问题
  .dialog-box {
    border-radius: 4px;
  }

  .bg {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 100%;
    height: 330px;
    background-size: cover;
    background-position: 50% 50%;
  }

  .close-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }

  .info-box {
    padding: 0px 80px;
  }

  .header-box {
    position: relative;
    margin-top: -115px;

    .avatar {
      width: 130px;
      height: 130px;
      border-radius: 4px;
      box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.2);
      margin-right: 20px;
    }

    .name-box {
      .name {
        font-size: 33px;
        font-weight: bold;
        line-height: 44px;
        color: #FFFFFF;
        text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
      }

      .series {
        font-size: 16px;
        font-weight: 400;
        line-height: 21px;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
        opacity: 0.9;
        margin-bottom: 8px;
      }
    }

    .enter-btn {
      margin-left: 300px;
      width: 97px;
      height: 29px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
      //background-image: url("../assets/product/dialog/enter-btn.png");
      cursor: pointer;
    }

    .view-now-btn {
      display: inline-block;
      padding: 10px 30px;
      background-color: #ff3829;
      color: #fff;
      font-size: 14px;
      margin-left: 200px;
      border-radius: 20px;
    }

  }

  .description-box {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: #333333;
    margin-top: 20px;

    .title {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      color: #333;
    }

    .content {
      margin-top: 5px;
      font-size: 14px;
      text-indent: 2em;
      font-weight: 400;
      line-height: 19px;
      color: #333333;
    }
  }

  .view-box {
    width: 100%;
    margin-top: 25px;
    margin-bottom: 35px;

    .video-box {
      width: 337px;
      height: 223px;
      position: relative;

      video {
        position: absolute;
        left: 0;
        top: 0;
        width: 337px;
        height: 223px;
        object-fit: fill;
        z-index: 1;
      }

      .play-btn {
        position: absolute;
        // 上下左右居中
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 54px;
        height: 54px;
        cursor: pointer;
        z-index: 2;
      }
    }

    .video-box-no {
      width: 337px;
      height: 223px;
      background-size: cover;
      background-position: 50% 50%;
      -webkit-backface-visibility: hidden;
    }

    .img-box {
      width: 695px;

      .img {
        width: 334px;
        height: 106px;
        cursor: pointer;

        &:nth-child(1), &:nth-child(2) {
          margin-bottom: 11px;
        }
      }
    }
  }
}

.member-list {
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 55px;
  width: 1240px;

  .el-card {
    border: none;
  }

  .avatar-list {
    .avatar-li {
      cursor: pointer;
      margin-top: 15px;
      margin-left: 34px;
      margin-right: 0px;

      .el-image {
        background-size: cover;
        background-position: 50% 50%;
        -webkit-backface-visibility: hidden;
        opacity: 1;
        transform: scale(1);
        cursor: pointer;


        &:hover {
          transition: all 0.3s linear;
          transform: scale(1.1);
        }
      }

      .name {
        text-align: center;
        margin-top: 15px;
        color: #888;
        font-size: 15px;
      }
    }
  }
}

.member-dialog {
  .history {
    text-align: left;
    font-size: 14px;
    color: #888;
  }

  .success {
    text-align: left;
    font-size: 14px;
    color: #888;
  }
}

// 友情链接
.link-container {
  margin-bottom: 45px;

  .list {
    margin-top: 40px;
    text-align: center;
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 285px);
    grid-gap: 10px;

    .name {
      margin-top: 10px;
      color: #777;
      font-size: 15px;
    }
  }


  .li {
    width: 285px;
    height: 140px;
    margin-top: 10px;
    //margin-right: 10px;
    background-size: cover;
    background-position: 50% 50%;
    -webkit-backface-visibility: hidden;
    opacity: 1;
    transform: scale(1);
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);

    &:hover {
      transition: all 0.3s linear;
      transform: scale(1.03);
    }
  }

  .more {
    text-align: center;
    cursor: pointer;
    transition: all 0.3s linear;
    width: 100px;
    margin: 0 auto;
    margin-top: 30px;
    color: #707070;

    &:hover {
      transform: scale(1.1);
    }

    span {
      font-weight: 400;
      line-height: 21px;
      color: #707070;
      font-size: 16px;
      margin-right: 3px;
    }

    img {
      width: 22px;
      height: 22px;
      display: inline-block;
      margin-left: 4px;
    }
  }
}

.page-footer {
  background-color: #2d2d2d;
  padding: 20px 0px;

  .info-container {
    .logo {
      width: 70px;
      height: 70px;
      cursor: pointer;
      display: none;
    }

    .infos {
      text-align: center;
      color: @remark-color;
      font-size: 14px;

      span {
        margin-right: 10px;
      }
    }
  }
}

.fullBtn {
  position: fixed;
  bottom: 3px;
  right: 20px;
  z-index: 99999;
}

.back-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 99999;

  img {
    width: 40px;
    height: 40px;
  }
}

.text-box .title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.text-box .des {
  color: #555;
  margin-bottom: 20px;
}

.text-box .li > div {
  margin-top: 5px;
  line-height: 18px;
}
</style>
